import Breadcrumb from "../../../components/molecules/breadcrumb"
import * as React from "react"
import { createContext, useContext, useEffect, useState } from "react"
import BodyCard from "../../../components/organisms/body-card"
import RawJSON from "../../../components/organisms/raw-json"
import { useMutation, useQuery, useQueryClient } from "react-query"
import api from "../../../services/api"
import Spinner from "../../../components/atoms/spinner"
import {
  FieldValues,
  FormProvider,
  useForm,
  useFormContext,
  UseFormMethods,
} from "react-hook-form"
import InputField from "../../../components/molecules/input"
import Textarea from "../../../components/molecules/textarea"
import toast from "react-hot-toast"
import Toaster from "../../../components/declarative-toaster"
import FormToasterContainer from "../../../components/molecules/form-toaster"
import { handleFormError } from "../../../utils/handle-form-error"

interface RecipeFormContextType {
  resetForm: () => void
  onSubmit: (values: any) => void
}

const RecipeFormContext = createContext<RecipeFormContextType | null>(null)

const useRecipeForm: () => UseFormMethods & RecipeFormContextType = () => {
  const formState = useFormContext()
  const recipeContext = useContext(RecipeFormContext)
  if (!recipeContext) {
    throw new Error("Use inside provider")
  }

  return { ...formState, ...recipeContext }
}

const RecipeFormProvider = ({ recipe, children }) => {
  const form = useForm({ defaultValues: recipe })
  const client = useQueryClient()
  const resetForm = () => {
    form.reset({ ...recipe })
  }
  const updateRecipe = useMutation(
    ["recipes", recipe.id],
    async (values) => api.recipes.save(recipe.id, values).then((r) => r.data),
    {
      onSuccess: (values) => {
        client.refetchQueries({
          predicate: (q) => q.queryKey.includes("recipes"),
        })
        form.reset(values)
      },
    }
  )
  const onSubmit = (values: any) => {
    updateRecipe.mutate(values)
  }

  return (
    <FormProvider {...form}>
      <RecipeFormContext.Provider
        value={{ resetForm, onSubmit: form.handleSubmit(onSubmit) }}
      >
        {children}
      </RecipeFormContext.Provider>
    </FormProvider>
  )
}
const RecipeForm = () => {
  const { register } = useFormContext()
  return (
    <form>
      <InputField
        name="title"
        label="Heiti"
        placeholder="Stutt heiti til uppskriftina"
        ref={register}
      />
      <div className="grid grid-cols-2 mt-4 gap-4">
        <InputField
          name="portions"
          label="Portiónir"
          placeholder=""
          ref={register}
        />
        <InputField name="time" label="Tíð" placeholder="" ref={register} />
      </div>
      <div className="grid grid-cols-2 mt-4 gap-4">
        <Textarea
          name="ingredients"
          label="Innihald"
          rows={10}
          ref={register}
        />
        <Textarea
          name="description"
          label="Mannagongd"
          rows={10}
          ref={register}
        />
      </div>
    </form>
  )
}

const TOAST_ID = "recipe-edit"

const UpdateNotification = ({ isLoading = false }) => {
  const { formState, handleSubmit, resetForm, onSubmit } = useRecipeForm()
  const [visible, setVisible] = useState(false)
  const [blocking, setBlocking] = useState(true)

  const onUpdate = (values: FieldValues) => {
    onSubmit({ ...values })
  }

  useEffect(() => {
    const timeout = setTimeout(setBlocking, 300, false)
    return () => clearTimeout(timeout)
  }, [])

  const isDirty = formState.isDirty

  useEffect(() => {
    if (!blocking) {
      setVisible(isDirty)
    }

    return () => {
      toast.dismiss(TOAST_ID)
    }
  }, [isDirty])

  return (
    <Toaster
      visible={visible}
      duration={Infinity}
      id={TOAST_ID}
      position="bottom-right"
    >
      <FormToasterContainer isLoading={isLoading}>
        <FormToasterContainer.Actions>
          <FormToasterContainer.ActionButton
            onClick={handleSubmit(onUpdate, handleFormError)}
          >
            Goym
          </FormToasterContainer.ActionButton>
          <FormToasterContainer.DiscardButton onClick={resetForm}>
            Angra
          </FormToasterContainer.DiscardButton>
        </FormToasterContainer.Actions>
      </FormToasterContainer>
    </Toaster>
  )
}

const EditRecipe = ({ id }) => {
  const { data: recipe, isLoading, refetch } = useQuery(
    ["recipes", id],
    async () => api.recipes.retrieve(id).then((r) => r.data)
  )

  return (
    <div className="pb-xlarge">
      <Breadcrumb
        currentPage="Broyt uppskrift"
        previousBreadcrumb="Uppskriftir"
        previousRoute="/a/recipes"
      />
      <BodyCard title="Norðfra uppskrift">
        {isLoading ? (
          <Spinner />
        ) : (
          <RecipeFormProvider recipe={recipe}>
            <RecipeForm />
            <UpdateNotification />
          </RecipeFormProvider>
        )}
      </BodyCard>
      <div className="mt-large">
        {isLoading ? <Spinner /> : <RawJSON data={recipe} title="Raw Recipe" />}
      </div>
    </div>
  )
}

export default EditRecipe
