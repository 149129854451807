import {Router} from '@reach/router'
import React from 'react'
import ManageRecipes from './manage'
import EditRecipe from './edit';

const Recipes = () => {
    return (
        <Router>
            <ManageRecipes path="/"/>
            <EditRecipe path=":id"/>
        </Router>
    )
}

export default Recipes
