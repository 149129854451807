import {RouteComponentProps} from '@reach/router'
import React, {useMemo} from 'react'
import api from '../../../services/api'
import {useQuery} from 'react-query';
import {usePagination, useTable} from 'react-table';
import Table from '../../../components/molecules/table';
import Badge from '../../../components/fundamentals/badge';
import BodyCard from '../../../components/organisms/body-card';
import clsx from 'clsx';
import Spinner from '../../../components/atoms/spinner';
import usePromotionActions from '../../../components/templates/discount-table/use-promotion-row-actions';
import {navigate} from 'gatsby';
import PlusIcon from '../../../components/fundamentals/icons/plus-icon';
import EditIcon from '../../../components/fundamentals/icons/edit-icon';

const ManageRecipes: React.FC<RouteComponentProps> = () => {

    return (
        <div className="h-full flex flex-col">
            <div className="w-full flex flex-col grow">
                <BodyCard title="Uppskriftir" actionables={[{
                    label: 'Stovna uppskrift',
                    onClick: () => navigate('/a/recipes/new'),
                    icon: (
                        <span className="text-grey-90">
                <PlusIcon size={20}/>
              </span>
                    ),
                },]}>
                    <RecipeTable/>
                </BodyCard>
            </div>
        </div>
    )
}

const RecipeTable = () => {
    const {data: recipes, isLoading} = useQuery(['recipes'], async () => api.recipes.list().then(r => r.data))
    const columns = useRecipeTableColumns()
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
    } = useTable(
        {
            columns,
            data: recipes || [],
            autoResetPage: false,
        },
        usePagination
    )

    return (
        <div className="w-full overflow-y-auto flex flex-col justify-between min-h-[300px] h-full ">
            <Table
                {...getTableProps()}
                className={clsx({['relative']: isLoading})}
            >
                <Table.Head>
                    {headerGroups?.map((headerGroup, index) => (
                        <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((col, headerIndex) => (
                                <Table.HeadCell {...col.getHeaderProps()}>
                                    {col.render('Header')}
                                </Table.HeadCell>
                            ))}
                        </Table.HeadRow>
                    ))}
                </Table.Head>
                {isLoading ? (
                    <div className="flex w-full h-full absolute items-center justify-center mt-10">
                        <div className="">
                            <Spinner size={'large'} variant={'secondary'}/>
                        </div>
                    </div>
                ) : (
                    <Table.Body {...getTableBodyProps()}>
                        {rows.map((row, rowIndex) => {
                            prepareRow(row)
                            return <RecipeRow row={row} key={rowIndex}/>
                        })}
                    </Table.Body>
                )}
            </Table>
        </div>
    )


}

const RecipeRow = ({row}) => {
    return (
        <Table.Row
            color={'inherit'}
            linkTo={`${row.original.id}`}
            {...row.getRowProps()}
            className="group"
        >
            {row.cells.map((cell, index) => {
                return cell.render('Cell', {index})
            })}
        </Table.Row>
    )
}

export const useRecipeTableColumns: () => any = () => {
    const columns = useMemo(
        () => [
            {
                id: 'id',
                Header: <Table.HeadCell className="pl-2">Nr.</Table.HeadCell>,
                accessor: 'id',
                Cell: ({cell: {value}, index}) => (
                    <Table.Cell key={index}>
                        <div className="overflow-hidden">
                            <Badge className="rounded-rounded" variant="default">
                                <span className="inter-small-regular">{value}</span>
                            </Badge>
                        </div>
                    </Table.Cell>
                ),
            },
            {
                id: 'title',
                Header: 'Heiti',
                accessor: 'title',
                Cell: ({cell: {value}, index}) => (
                    <Table.Cell key={index}>{value}</Table.Cell>
                ),
            },
        ],
        []
    )

    return columns
}

export default ManageRecipes
