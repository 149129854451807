import React from "react"
import { ItemsFulfilledEvent } from "../../../hooks/use-build-timeline"
import PackageIcon from "../../fundamentals/icons/package-icon"
import EventContainer from "./event-container"
import EventItemContainer from "./event-item-container"

type ItemsFulfilledProps = {
    event: ItemsFulfilledEvent
}

const ItemsFulfilled: React.FC<ItemsFulfilledProps> = ({ event }) => {
    const title =
        event.sourceType === "claim"
            ? "Claim Items Fulfilled"
            : event.sourceType === "exchange"
            ? "Exchange Items Fulfilled"
            : "Vørur útflýggjaðar"

    const args = {
        icon: <PackageIcon size={20} />,
        time: event.time,
        title: title,
        children: event.items.map((item) => <EventItemContainer item={item} />),
        noNotification: event.noNotification,
        isFirst: event.first,
    }
    return <EventContainer {...args} />
}

export default ItemsFulfilled
