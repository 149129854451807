import { RouteComponentProps, useLocation } from "@reach/router"
import clsx from "clsx"
import { isEmpty } from "lodash"
import { useAdminOrders } from "medusa-react"
import qs from "qs"
import React, { useEffect, useState } from "react"
import { usePagination, useTable } from "react-table"
import { FeatureFlagContext } from "../../../context/feature-flag"
import Spinner from "../../atoms/spinner"
import Table, { TablePagination } from "../../molecules/table"
import OrderFilters from "../order-filter-dropdown"
import useOrderTableColums from "./use-order-column"
import { useOrderFilters } from "./use-order-filters"

const DEFAULT_PAGE_SIZE = 15

const defaultQueryProps = {
    expand: "shipping_address",
    fields:
        "id,status,display_id,created_at,email,fulfillment_status,payment_status,total,currency_code",
}

const OrderTable: React.FC<RouteComponentProps> = () => {
    const location = useLocation()

    const { isFeatureEnabled } = React.useContext(FeatureFlagContext)

    let hiddenColumns = ["sales_channel"]
    if (isFeatureEnabled("sales_channels")) {
        defaultQueryProps.expand = "shipping_address,sales_channel"
        hiddenColumns = []
    }

    const {
        removeTab,
        setTab,
        saveTab,
        availableTabs: filterTabs,
        activeFilterTab,
        reset,
        paginate,
        setFilters,
        filters,
        setQuery: setFreeText,
        queryObject,
        representationObject,
    } = useOrderFilters(location.search, defaultQueryProps)
    const filtersOnLoad = queryObject

    const offs = parseInt(filtersOnLoad?.offset) || 0
    const lim = parseInt(filtersOnLoad.limit) || DEFAULT_PAGE_SIZE

    const [query, setQuery] = useState(filtersOnLoad?.query)
    const [numPages, setNumPages] = useState(0)

    const { orders, isLoading, count } = useAdminOrders(queryObject)

    useEffect(() => {
        const controlledPageCount = Math.ceil(count! / queryObject.limit)
        setNumPages(controlledPageCount)
    }, [orders])

    const [columns] = useOrderTableColums()

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        // Get the state from the instance
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data: orders || [],
            manualPagination: true,
            initialState: {
                pageSize: lim,
                pageIndex: offs / lim,
                hiddenColumns,
            },
            pageCount: numPages,
            autoResetPage: false,
        },
        usePagination
    )

    // Debounced search
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (query) {
                setFreeText(query)
                gotoPage(0)
            } else {
                // if we delete query string, we reset the table view
                reset()
            }
        }, 400)

        return () => clearTimeout(delayDebounceFn)
    }, [query])

    const handleNext = () => {
        if (canNextPage) {
            paginate(1)
            nextPage()
        }
    }

    const handlePrev = () => {
        if (canPreviousPage) {
            paginate(-1)
            previousPage()
        }
    }

    const updateUrlFromFilter = (obj = {}) => {
        const stringified = qs.stringify(obj)
        window.history.replaceState(`/a/orders`, "", `${`?${stringified}`}`)
    }

    const refreshWithFilters = () => {
        const filterObj = representationObject

        if (isEmpty(filterObj)) {
            updateUrlFromFilter({ offset: 0, limit: DEFAULT_PAGE_SIZE })
        } else {
            updateUrlFromFilter(filterObj)
        }
    }

    const clearFilters = () => {
        reset()
        setQuery("")
    }

    useEffect(() => {
        refreshWithFilters()
    }, [representationObject])

    return (
        <div className="w-full overflow-y-auto flex flex-col justify-between min-h-[300px] h-full ">
            <Table
                filteringOptions={
                    <OrderFilters
                        filters={filters}
                        submitFilters={setFilters}
                        clearFilters={clearFilters}
                        tabs={filterTabs}
                        onTabClick={setTab}
                        activeTab={activeFilterTab}
                        onRemoveTab={removeTab}
                        onSaveTab={saveTab}
                    />
                }
                enableSearch
                searchPlaceholder="Leita"
                handleSearch={setQuery}
                searchValue={query}
                {...getTableProps()}
                className={clsx({ ["relative"]: isLoading })}
            >
                <Table.Head>
                    {headerGroups?.map((headerGroup, index) => (
                        <Table.HeadRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((col, headerIndex) => (
                                <Table.HeadCell {...col.getHeaderProps()}>
                                    {col.render("Header")}
                                </Table.HeadCell>
                            ))}
                        </Table.HeadRow>
                    ))}
                </Table.Head>
                {isLoading || !orders ? (
                    <div className="flex w-full h-full absolute items-center justify-center mt-10">
                        <div className="">
                            <Spinner size={"large"} variant={"secondary"} />
                        </div>
                    </div>
                ) : (
                    <Table.Body {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row)
                            return (
                                <Table.Row
                                    color={"inherit"}
                                    linkTo={row.original.id}
                                    {...row.getRowProps()}
                                    className="group"
                                >
                                    {row.cells.map((cell, index) => {
                                        return cell.render("Cell", { index })
                                    })}
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                )}
            </Table>
            <TablePagination
                count={count!}
                limit={queryObject.limit}
                offset={queryObject.offset}
                pageSize={queryObject.offset + rows.length}
                title="Orders"
                currentPage={pageIndex + 1}
                pageCount={pageCount}
                nextPage={handleNext}
                prevPage={handlePrev}
                hasNext={canNextPage}
                hasPrev={canPreviousPage}
            />
        </div>
    )
}

export default OrderTable
