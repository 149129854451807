import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { useAdminRegions } from "medusa-react"
import FilterDropdownContainer from "../../../components/molecules/filter-dropdown/container"
import FilterDropdownItem from "../../../components/molecules/filter-dropdown/item"
import SaveFilterItem from "../../../components/molecules/filter-dropdown/save-field"
import TabFilter from "../../../components/molecules/filter-tab"
import PlusIcon from "../../fundamentals/icons/plus-icon"

const REGION_PAGE_SIZE = 10

const statusFilters = [
    "completed",
    "pending",
    "canceled",
    "archived",
    "requires_action",
]
const paymentFilters = [
    "awaiting",
    "captured",
    "refunded",
    "canceled",
    "partially_refunded",
    "requires_action",
    "not_paid",
]
const fulfillmentFilters = [
    "fulfilled",
    "not_fulfilled",
    "partially_fulfilled",
    "returned",
    "partially_returned",
    "shipped",
    "partially_shipped",
    "requires_action",
    "canceled",
]

const dateFilters = [
    "is in the last",
    "is older than",
    "is between",
    "is after",
    "is before",
    "is equal to",
]

const OrderFilters = ({
    tabs,
    activeTab,
    onTabClick,
    onSaveTab,
    onRemoveTab,
    filters,
    submitFilters,
    clearFilters,
}) => {
    const [tempState, setTempState] = useState(filters)
    const [name, setName] = useState("")

    const handleRemoveTab = (val) => {
        if (onRemoveTab) {
            onRemoveTab(val)
        }
    }

    const handleSaveTab = () => {
        if (onSaveTab) {
            onSaveTab(name, tempState)
        }
    }

    const handleTabClick = (tabName: string) => {
        if (onTabClick) {
            onTabClick(tabName)
        }
    }

    useEffect(() => {
        setTempState(filters)
    }, [filters])

    const onSubmit = () => {
        submitFilters(tempState)
    }

    const onClear = () => {
        clearFilters()
    }

    const setSingleFilter = (filterKey, filterVal) => {
        setTempState((prevState) => ({
            ...prevState,
            [filterKey]: filterVal,
        }))
    }

    const numberOfFilters = Object.entries(filters).reduce(
        (acc, [key, value]) => {
            if (value?.open) {
                acc = acc + 1
            }
            return acc
        },
        0
    )

    const [regionsPagination, setRegionsPagination] = useState({
        offset: 0,
        limit: REGION_PAGE_SIZE,
    })

    const { regions, count, isLoading: isLoadingRegions } = useAdminRegions(
        regionsPagination
    )

    const handlePaginateRegions = (direction) => {
        if (direction > 0) {
            setRegionsPagination((prev) => ({
                ...prev,
                offset: prev.offset + prev.limit,
            }))
        } else if (direction < 0) {
            setRegionsPagination((prev) => ({
                ...prev,
                offset: Math.max(prev.offset - prev.limit, 0),
            }))
        }
    }

    return (
        <div className="flex space-x-1">
            <FilterDropdownContainer
                submitFilters={onSubmit}
                clearFilters={onClear}
                triggerElement={
                    <button
                        className={clsx(
                            "flex rounded-rounded items-center space-x-1 focus-visible:outline-none focus-visible:shadow-input focus-visible:border-violet-60"
                        )}
                    >
                        <div className="flex rounded-rounded items-center bg-grey-5 border border-grey-20 inter-small-semibold px-2 h-6">
                            Filtur
                            <div className="text-grey-40 ml-1 flex items-center rounded">
                                <span className="text-violet-60 inter-small-semibold">
                                    {numberOfFilters ? numberOfFilters : "0"}
                                </span>
                            </div>
                        </div>
                        <div className="flex items-center rounded-rounded bg-grey-5 border border-grey-20 inter-small-semibold p-1">
                            <PlusIcon size={14} />
                        </div>
                    </button>
                }
            >
                <FilterDropdownItem
                    filterTitle="Støða"
                    options={statusFilters}
                    filters={tempState.status.filter}
                    open={tempState.status.open}
                    setFilter={(val) => setSingleFilter("status", val)}
                />
                <FilterDropdownItem
                    filterTitle="Gjaldsstøða"
                    options={paymentFilters}
                    filters={tempState.payment.filter}
                    open={tempState.payment.open}
                    setFilter={(val) => setSingleFilter("payment", val)}
                />
                <FilterDropdownItem
                    filterTitle="Útflýggjanarstøða"
                    options={fulfillmentFilters}
                    filters={tempState.fulfillment.filter}
                    open={tempState.fulfillment.open}
                    setFilter={(val) => setSingleFilter("fulfillment", val)}
                />
                <FilterDropdownItem
                    filterTitle="Land"
                    options={
                        regions?.map((region) => ({
                            value: region.id,
                            label: region.name,
                        })) || []
                    }
                    isLoading={isLoadingRegions}
                    hasPrev={regionsPagination.offset > 0}
                    hasMore={
                        regionsPagination.offset + regionsPagination.limit <
                        (count ?? 0)
                    }
                    onShowPrev={() => handlePaginateRegions(-1)}
                    onShowNext={() => handlePaginateRegions(1)}
                    filters={tempState.region.filter}
                    open={tempState.region.open}
                    setFilter={(v) => setSingleFilter("region", v)}
                />
                <FilterDropdownItem
                    filterTitle="Dagfesting"
                    options={dateFilters}
                    filters={tempState.date.filter}
                    open={tempState.date.open}
                    setFilter={(val) => setSingleFilter("date", val)}
                />
                <SaveFilterItem
                    saveFilter={handleSaveTab}
                    name={name}
                    setName={setName}
                />
            </FilterDropdownContainer>
            {tabs &&
                tabs.map((t) => (
                    <TabFilter
                        key={t.value}
                        onClick={() => handleTabClick(t.value)}
                        label={t.label}
                        isActive={activeTab === t.value}
                        removable={!!t.removable}
                        onRemove={() => handleRemoveTab(t.value)}
                    />
                ))}
        </div>
    )
}

export default OrderFilters
